
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import store from "@/store";

export default defineComponent({
  name: "CZEnergyMarketPriceReport",
  components: {},
  props: {
    widgetClasses: String,
  },
  setup() {
    const foldersList = ref();
    const flag = ref(0);
    onMounted(() => {
      setCurrentPageBreadcrumbs("Energy Market Intelligence", ["NA"]);
    });
    let AccountName = store.getters.AccountName;
    const rootFolderName = ref("");
    const rootpath = ref("");
    ApiService.get("api/docxchange/getfilesfolders/" + AccountName + "/_root")
      .then(async ({ data }) => {
        foldersList.value = await data;

        rootpath.value = data[0].FilePath;
        flag.value = 0;
      })
      .catch();

    /**
     *@description Get folders from the given path
     */

    function getFolders(filePath, fileName, fileType) {
      if (filePath === rootpath.value) {
        rootFolderName.value = fileName;
      }

      let fn = "";
      if (!filePath.includes(rootFolderName.value)) {
        fn = rootFolderName.value;
      } else {
        fn = filePath.substring(filePath.lastIndexOf(rootFolderName.value));
        fn = fileType === "D" ? fn + "^" + fileName : fn;
      }

      if (fileType === "F") {
        let re = /\\/gi;
        let fullpath = fn.replace(re, "^");
        downloadItem(fileName, fullpath);
      } else {
        let re = /\\/gi;
        let fullpath = fn.replace(re, "^");

        ApiService.get(
          "api/docxchange/getfilesfolders/" + AccountName + "/" + fullpath
        )
          .then(async ({ data }) => {
            foldersList.value = await data;
            if (data.length == 0) {
              Swal.fire({
                text: "Folder Is Empty",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });

              goBack(filePath);
            } else {
              flag.value = data.length > 0 ? 1 : 0;
            }
          })
          .catch();
      }
    }

    /**
     *@description back to the folders from the current path
     */

    function goBack(path) {
      let Filepath = path.split("\\");
      Filepath.splice(-1, 1);
      let val = Filepath.join("^");

      let fn = !val.includes(rootFolderName.value)
        ? "_root"
        : val.substring(val.lastIndexOf(rootFolderName.value));

      flag.value = fn == "_root" ? 0 : 1;
      ApiService.get("api/docxchange/getfilesfolders/" + AccountName + "/" + fn)
        .then(async ({ data }) => {
          foldersList.value = await data;
        })
        .catch();
    }

    /**
     *@description download a files from the given file path
     */
    async function downloadItem(FileName, filePath) {
      let Fname = FileName.split(".");
      let url =
        ApiService.vueInstance.axios.defaults.baseURL +
        "api/Downloadfile/getFile/" +
        AccountName +
        "/pdf" +
        "/" +
        filePath +
        "/" +
        Fname[0] +
        "/" +
        0;
      // let url="https://192.168.1.25/api/Downloadfile/getFile/pdf/Market Report (Midday) - 16-10-2019";
      window.open(url);
    }
    return {
      foldersList,
      downloadItem,
      getFolders,
      flag,
      goBack,
    };
  },
});
